<template>
  <div class="not-found">
    <h1 style="text-align: center;font-family: 'Comfortaa', cursive !important;">Not Found</h1>
    <router-link to="/" style="align-self: center; color: #bac964; font-family: 'Poiret One', cursive !important;">Go Home</router-link>
  </div>
</template>

<style>
.not-found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
</style>

<script setup>
// import { onMounted } from "vue";
document.title = "Not Found"

// onMounted(()=>document.title = "Not Found")
</script>
