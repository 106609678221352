<template>
  <!--<nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <nav class="navbar navbar-expand-lg site-navbar navbar-light bg-light scrolled" id="pb-navbar">
    <div class="container">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09"
        aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
<i class="fa fa-bars" style="color: #fff"></i>
      </button>

      <div style="font-family: 'Comfortaa', cursive !important"
        class="collapse navbar-collapse justify-content-md-center" id="navbarsExample09">
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link" href="/#section-home">Home</a></li>
          <li class="nav-item"><a class="nav-link" href="/#section-portfolio">Musical Works</a></li>
          <li class="nav-item"><a class="nav-link" href="/#section-about">About</a></li>
          <li class="nav-item"><a class="nav-link" href="/#section-contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&family=Nunito+Sans:wght@200&family=Square+Peg&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Poiret+One&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
