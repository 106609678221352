import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFound from '@/views/NotFound.vue'
import Composition from '@/views/Composition.vue'
import Recording from '@/views/Recording.vue'
import Performance from '@/views/Performance.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/compositions/:id',
    name: 'composition',
    component: Composition
  },
  {
    path: '/recordings/:id',
    name: 'recording',
    component: Recording
  },
  {
    path: '/performances/:id',
    name: 'performance',
    component: Performance
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
