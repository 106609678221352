export default [
    {
        id: 'bua',
        title: "Bowen Univeristy Anthem",
        year: 2005,
        author: "Kolawole Olawale",
        data: ["bua.pdf"],
        banner: "bowen.png",
        type: "composition"
    },
    {
        id: 'jesu-oba-ogo',
        title: "Jesu Oba Ogo",
        year: 2007,
        author: "Kolawole Emmanuel Olawale",
        data: ["jesu-oba-ogo.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'acoe',
        title: "Adeyemi College of Education Anthem",
        year: 2012,
        author: "Kolawole Emmanuel Olawale & Music Department ACOED",
        data: ["acoe.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'aua',
        title: "American University of Nigeria Anthem",
        year: 2008,
        author: "Kolawole Emmanuel Olawale & A. Adegbite",
        data: ["aua.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'bus',
        title: "Bowen University High School Anthem",
        year: 2021,
        author: "Kolawole Emmanuel Olawale & E.A. Ogunwole",
        data: ["bus.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'oscoe',
        title: "Osun State College of Education Ilesha Anthem",
        year: 2005,
        author: "Kolawole Emmanuel Olawale & Department of Music OSCOED",
        data: ["oscoe.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'eyin-ogongosu',
        title: "Eyin Ogongosu",
        year: 2012,
        author: "Kolawole Emmanuel Olawale",
        data: ["eyin-ogongosu.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'olurombi',
        title: "Olurombi",
        year: 2012,
        author: "Kolawole Emmanuel Olawale",
        data: ["olurombi.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'oba-alase',
        title: "Oba Alase",
        year: 2012,
        author: "Kolawole Emmanuel Olawale",
        data: ["oba-alase.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'okete',
        title: "Okete",
        year: 2012,
        author: "Kolawole Emmanuel Olawale",
        data: ["okete.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'ode-ma-pa-mi',
        title: "Ode Ma Pa Mi",
        year: 2012,
        author: "Kolawole Emmanuel Olawale",
        data: ["ode-ma-pa-mi.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'ijuba-jesu',
        title: "Ijuba Jesu",
        year: 2003,
        author: "Kolawole Emmanuel Olawale & A. Adegbite",
        data: ["ijuba-jesu.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'ise-oluwa',
        title: "Ise Oluwa",
        year: 2009,
        author: "Kolawole Olawale",
        data: ["ise-oluwa.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'olorun-ni-oro',
        title: "Olorun Ni Oro",
        year: 2003,
        author: "Kolawole Emmanuel Olawale & A. Adegbite",
        data: ["olorun-ni-oro.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'were-lo-ba-wa-se',
        title: "Were Lo Ba Wa Se",
        year: 2006,
        author: "Kolawole Olawale",
        data: ["were-lo-ba-wa-se.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'yak-itoro-obong-abasi',
        title: "Yak Itoro Obong Abasi",
        year: 2005,
        author: "Kolawole Olawale",
        data: ["yak-itoro-obong-abasi.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'olusegun',
        title: "Olusegun",
        year: 2007,
        author: "Kolawole Olawale",
        data: ["olusegun.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'okelerin',
        title: "Okelerin Anthem",
        year: 2019,
        author: "Kolawole Olawale",
        data: ["okelerin.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'otan-ayegbaju-anthem',
        title: "Otan-Ayegbaju Anthem",
        year: 2006,
        author: "Kolawole Emmanuel Olawale & A. Adegbite",
        data: ["otan-ayegbaju-anthem.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'ase-kedi',
        title: "Ase Kedi (Piano Duet)",
        year: 2004,
        author: "Kolawole Olawale",
        data: ["ase-kedi.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'beggars-dance',
        title: "Beggars' Dance",
        year: 2006,
        author: "Kolawole Olawale",
        data: ["beggars-dance.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'dafbap',
        title: "Danze Africano for Bass and Piano",
        year: 2006,
        author: "Kolawole Emmanuel Olawale & A. Adegbite",
        data: ["dafbap.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'sanctify-me',
        title: "Sanctify Me",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["sanctify-me.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'rest-thy-servant-is-sleeping',
        title: "Rest, Thy Servant is Sleeping",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["rest-thy-servant-is-sleeping.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'gba-gbogbo-aye-mi',
        title: "Gba Gbogbo Aye mi",
        year: 2005,
        author: "Kolawole Olawale",
        data: ["gba-gbogbo-aye-mi.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'farewell',
        title: "Farewell",
        year: 1993,
        author: "Kolawole Olawale",
        data: ["farewell.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'a-virtuous-woman',
        title: "A Virtuous Woman",
        year: 2002,
        author: "Kolawole Olawale",
        data: ["a-virtuous-woman.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'imototo',
        title: "Imototo",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["imototo.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'we-ki-o-mo',
        title: "We ki o mo",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["we-ki-o-mo.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'tisa-wa',
        title: "Tisa wa",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["tisa-wa.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'omo-to-mo-iya-re-loju',
        title: "Omo to mo Iya re l'oju",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["omo-to-mo-iya-re-loju.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'olodo',
        title: "Olodo Rapata",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["olodo.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'ole',
        title: "Ole",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["ole.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'iwe-kika-dara',
        title: "Iwe Kika Dara",
        year: 2010,
        author: "Kolawole Olawale",
        data: ["iwe-kika-dara.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'halleluyah-praise-the-lord',
        title: "Halleluyah! Praise the Lord",
        year: 2012,
        author: "Kolawole Olawale",
        data: ["halleluyah-praise-the-lord.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'o-for-a-thousand-tongues-to-sing',
        title: "O for a Thousand Tongues to Sing",
        year: '',
        author: "Kolawole Olawale",
        data: ["o-for-a-thousand-tongues-to-sing.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'rejoice',
        title: "Rejoice for Mixed Choir",
        year: 2012,
        author: "Kolawole Olawale",
        data: ["rejoice.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'rejoice-solo',
        title: "Rejoice for Solo and Choir",
        year: 2016,
        author: "Kolawole Olawale",
        data: ["rejoice-solo.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'ride-the-chariot',
        title: "Ride the Chariot",
        year: 2021,
        author: "Kolawole Olawale",
        data: ["ride-the-chariot.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'soaring-higher',
        title: "Soaring Higher",
        year: 2016,
        author: "Kolawole Olawale",
        data: ["soaring-higher.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'steal-away-to-jesus',
        title: "Steal Away to Jesus",
        year: '',
        author: "Kolawole Olawale",
        data: ["steal-away-to-jesus.pdf"],
        banner: "banner.jpg",
        type: "composition"
    },
    {
        id: 'bowen-choir-jubilation',
        title: "Bowen Choir | Jubilation",
        year: '2010',
        author: "Kolawole Olawale & Bowen Choir",
        data: [
            {
                title:"Bowen Anthem", 
                data: "bowen-anthem.mp3"
            },
            {
                title:"Era Nne by 'O Ndubuisi", 
                data: "era-nne-by-o-ndubuisi.mp3"
            },
            {
                title: "Grace Greater than our Sin", 
                data: "grace-greater-than-our-sins.mp3"
            },
            {
                title:"I will Give Thanks", 
                data: "i-will-give-thanks.mp3"
            },
            {
                title:"Ise Oluwa", 
                data: "ise-oluwa.mp3"
            },
            {
                title:"Jesu Joy of Man's Desiring", 
                data: "jesu-joy-of-mans-desiring.mp3"
            },
            {
                title:"Just a closer walk with Thee", 
                data: "just-a-closer-walk-with-thee.mp3"
            },
            {
                title:"Kabiyesi O by James Yankey", 
                data: "kabiyesi-o-by-james-yankey.mp3"
            },
            {
                title:"Kauna by I. D. Yarison", 
                data: "kauna-by-id-yarison.mp3"
            },
            {
                title:"O Happy Day", 
                data: "o-happy-day.mp3"
            },
            {
                title:"Sing unto God 1", 
                data: "sing-unto-god.mp3"
            },
            {
                title:"Sing unto God 2", 
                data: "sing-unto-god2.mp3"
            },
            {
                title:"Yak Itoro Obong Abasi", 
                data: "yak-itoro-obong-abasi.mp3"
            }
        ],
        banner: "bowen.png",
        type: "recording"
    },/**
    {
        id: 'baptist-convension',
        title: "Baptist Convension",
        year: '',
        author: "Kolawole Olawale",
        data: [],
        banner: "bowen.png",
        type: "performance"
    },*/
    {
        id: 'oriental-yak-itoro-obong-abasi',
        title: "Oriental | Yak Itoro Obong Abasi Performance",
        year: '2013',
        author: "Kolawole Olawale",
        data: [
            {
                title:"", 
                data: "yak-itoro-obong-abasi.mp4"
            }
        ],
        banner: "bowen.png",
        type: "performance"
    },
    {
        id: 'oriental-welcome-song',
        title: "Welcome song | Oriental Hotel Lekki, Lagos",
        year: '2013',
        author: "Kolawole Olawale",
        data: [
            {
                title: "",
                data: "oriental-welcome-song.mp4"
            }
        ],
        banner: "bowen.png",
        type: "performance"
    },
    {
        id: 'oriental-glorious-is-thy-name',
        title: "Oriental | Glorious is thy name",
        year: '2013',
        author: "W.A. Mozart",
        data: [
            {
                title:"", 
                data: "oriental-glorious-is-thy-name.mp4"
            }
        ],
        banner: "bowen.png",
        type: "performance"
    },
    {
        id: 'sheraton-concert-2008',
        title: "2008 concert @ sheraton",
        year: '2008',
        author: "Kolawole Olawale",
        data: [
            {
                title:"", 
                data: "sheraton-2008-01-1.mp4"
            }
        ],
        banner: "bowen.png",
        type: "performance"
    },
    {
        id: 'run-easter-concert-2011',
        title: "Redeemers University Easter Concert 2011",
        year: '2011',
        author: "Kolawole Olawale",
        data: [
            {
                title:"One", 
                data: "run-01-1.mp4"
            },
            {
                title:"Two", 
                data: "run-01-2.mp4"
            },
            {
                title:"Three", 
                data: "run-01-3.mp4"
            },
            {
                title:"Four", 
                data: "run-02-1.mp4"
            },
            {
                title:"Five", 
                data: "run-03-1.mp4"
            }
        ],
        banner: "bowen.png",
        type: "performance"
    },
    {
        id: '2016-easter-concert',
        title: "2016 Easter Concert",
        year: '2016',
        author: "Kolawole Olawale",
        data: [
            {
                title:"", 
                data: "2016-easter-concert.mp4"
            }
        ],
        banner: "bowen.png",
        type: "performance"
    },
    {
        id: '2019-peace-concert',
        title: "2019 Peace Concert",
        year: '2019',
        author: "Kolawole Olawale",
        data: [
            {
                title:"", 
                data: "2019-peace-concert.webm"
            }
        ],
        banner: "bowen.png",
        type: "performance"
    },
    {
        id: 'bowen-20th-anniversary',
        title: "Bowen 20th Anniversary",
        year: '2022',
        author: "Kolawole Olawale",
        data: [
            {
                title:"", 
                data: "bowen-20th-anniversary.mp4"
            }
        ],
        banner: "bowen.png",
        type: "performance"
    }
    
]